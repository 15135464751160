import {
  LogLevel,
  Configuration,
  BrowserCacheLocation,
} from "@azure/msal-browser";

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_dev_phone_sign_signup",
    // signUpSignIn: "b2c_1_dev_refer_signup_signin",
  },
  authorities: {
    signUpSignIn: {
      authority:
        "https://botnotchmsuae.b2clogin.com/botnotchmsuae.onmicrosoft.com/B2C_1_dev_phone_sign_signup",
    },
  },
  authorityDomain: "botnotchmsuae.b2clogin.com",
};

export const msalConfig: Configuration = {
  auth: {
    clientId: "601b0bac-323b-42af-af47-147220e5d4ab",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: "/login",
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback: (logLevel, message, containsPii) => {},
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

export const protectedResources = {
  appListApi: {
    endpoint: "https://patients.dahc.mycybercns.com/dashboards/project",
    scopes: ["https://botnotchmsuae.b2clogin.com/api/tasks.read"],
  },
};
export const loginRequest = {
  scopes: [],
};
